import React from "react";
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import { useDispatch } from "react-redux";
import { openSidebar } from "../../redux/sliceContext";

import { Link } from "gatsby";
const Logo = () => {
  // useDispatch
  const dispatch = useDispatch();

  // function that open sidebar
  const handleOpenSidebar = () => {
    dispatch(openSidebar());
  };
  return (
    <div className="menu-mobile">
      <Link to={"/"} className="logo-link">
        <h2>
          <span className="text-black">&lt;</span> SLV Developer{" "}
          <span className="text-black">/&gt;</span>
        </h2>
      </Link>

      <div>
        <button
          onClick={handleOpenSidebar}
          className="btn-icon btn-hamburger-menu"
          aria-label="hamburger-menu"
        >
          <GiHamburgerMenu className="icon" />
        </button>
      </div>
    </div>
  );
};

export default Logo;
