import React from "react";

const Loading = () => {
  return (
    <div className="loading" id="loader-wrapper">
      <h1>
        Silvio Ferrentino <span>Portfolio</span>
      </h1>
    </div>
  );
};

export default Loading;
