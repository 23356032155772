import "./src/style.css";
import wrapWithProvider from "./wrap-with-provider";
export const wrapRootElement = wrapWithProvider;
export const onClientEntry = () => {
  setTimeout(() => {
    if (document.getElementById("loader-wrapper")) {
      document.getElementById("loader-wrapper").style.display = "flex";
    }
  }, 0);
};

export const onInitialClientRender = () => {
  setTimeout(() => {
    if (document.getElementById("loader-wrapper")) {
      document.getElementById("loader-wrapper").style.display = "flex";
    }
  }, 0);
};

export const onRouteUpdate = () => {
  setTimeout(() => {
    if (document.getElementById("loader-wrapper")) {
      document.getElementById("loader-wrapper").style.display = "none";
    }
  }, 2000);
};
