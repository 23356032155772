import React from "react";
import { Link } from "gatsby";
import { useDispatch } from "react-redux";
import { closeSidebar } from "../../redux/sliceContext";

const LinksSidebar = () => {
  //useDispatch
  const dispatch = useDispatch();

  // function that close sideBar
  const handleCloseSidebar = () => {
    dispatch(closeSidebar());
  };
  return (
    <div className="links-container">
      <Link to={"/"} className="link-sidebar" onClick={handleCloseSidebar}>
        Home
      </Link>
      <Link to={"/about"} className="link-sidebar" onClick={handleCloseSidebar}>
        About
      </Link>
      <Link
        to={"/projects"}
        className="link-sidebar"
        onClick={handleCloseSidebar}
      >
        Projects
      </Link>
      <Link
        to={"/contact"}
        className="link-sidebar"
        onClick={handleCloseSidebar}
      >
        Contact
      </Link>
    </div>
  );
};

export default LinksSidebar;
