import React from "react";
import SocialLinks from "./SocialLinks";
// import styles
import "./footer.css";
// import  icon
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import { MdEmail } from "@react-icons/all-files/md/MdEmail";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="contacts">
          <div className="icon-contact">
            <MdEmail />
            <p>slvwebdeveloper@gmail.com</p>
          </div>
          <div className="icon-contact">
            <FaPhoneAlt />
            <p>+39 3278353521</p>
          </div>
        </div>
        <SocialLinks />
      </div>
    </footer>
  );
};

export default Footer;
