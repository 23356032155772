import React from "react";
// import styles
import "./navbar.css";
import Links from "./Links";
import Logo from "./Logo";

const Navbar = () => {
  return (
    <nav className="navbar-container">
      <div className="navbar">
        <Logo />
        <Links />
      </div>
    </nav>
  );
};

export default Navbar;
