import React from "react";
import { Provider } from "react-redux";
import { store } from "./src/redux/store";

import Layout from "./src/components/Layout";

// eslint-disable-next-line react/display-name,react/prop-types
const wrapWithProvider = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <Provider store={store}>
      <Layout>{element}</Layout>
    </Provider>
  );
};

export default wrapWithProvider;
