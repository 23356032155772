// import React Icons
import React from "react";

import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaInstagramSquare } from "@react-icons/all-files/fa/FaInstagramSquare";

const SocialLinks = () => {
  return (
    <div className="social-container-footer">
      <a
        href="https://www.facebook.com/silvio.ferrentino"
        aria-label="profile facebook"
        target="a_blank"
      >
        <FaFacebookSquare className="social-icon" />
      </a>
      <a
        href="https://www.instagram.com/silvioferrentino/"
        aria-label="profile instagram"
        target="a_blank"
      >
        <FaInstagramSquare className="social-icon" />
      </a>
      <a
        href="https://www.linkedin.com/in/silvio-ferrentino-84612b290/"
        aria-label="profile linkedin"
        target="a_blank"
      >
        <FaLinkedin className="social-icon" />
      </a>
    </div>
  );
};

export default SocialLinks;
