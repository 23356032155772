import React from "react";
import { Link } from "gatsby";

const Links = () => {
  return (
    <div className="nav-links">
      <Link to={"/"} className="link">
        Home
      </Link>
      <Link to={"/about"} className="link">
        About
      </Link>
      <Link to={"/projects"} className="link">
        Projects
      </Link>
      <Link to={"/contact"} className="link link-contacts">
        Contact
      </Link>
    </div>
  );
};

export default Links;
