import React from "react";
import "./sidebar.css";
import { Link } from "gatsby";
import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { closeSidebar } from "../../redux/sliceContext";
import LinksSidebar from "./LinksSidebar";
import SocialLinks from "./SocialLinks";

const Sidebar = () => {
  // useSelector
  const { isOpen } = useSelector((state) => state.contextSlice);

  //useDispatch
  const dispatch = useDispatch();

  // function that close sideBar
  const handleCloseSidebar = () => {
    dispatch(closeSidebar());
  };

  return (
    <div
      className={`sidebar-container ${
        isOpen && "sidebar-container open-sidebar"
      }`}
    >
      <div className="sidebar">
        <div className="menu-sidebar">
          <Link to={"/"} className="logo-link">
            <h2>
              <span className="text-black">&lt;</span> SLV Developer{" "}
              <span className="text-black">/&gt;</span>
            </h2>
          </Link>

          <div>
            <button className="btn-icon" onClick={handleCloseSidebar}>
              <IoMdCloseCircle className="close-icon" />
            </button>
          </div>
        </div>
        <LinksSidebar />
        <SocialLinks />
      </div>
    </div>
  );
};

export default Sidebar;
